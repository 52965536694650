import { IProductInfo, ServiceProvider, Products } from "../env-types";
import { detect } from 'detect-browser';

export const cc: IProductInfo = {
  ProductName: "AHAU 260",
  ProductTitle: "AHAU 260 for Adobe Creative Cloud",
  AppCode: Products.cc,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://ahau260.com/",
  GetStartedPath: "https://ahausoftware.atlassian.net/wiki/spaces/AHAU260/pages/1168277642/Installation+for+Adobe+Creative+Cloud",
  EndUserAgreementPath:
    "https://ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://ahau260.com/terms-of-use",
  OrderPath: "https://ahau260.com/#prices",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};

function getBrowserName(): string {
  try {
    const dt = detect();
    const browser = dt && dt.name;

    switch (browser) {
    case "firefox":
      return "Firefox";

    case "chrome":
      return "Chrome";

    case "edge-chromium":
      return "Edge";

    case "opera":
      return "Opera";
    }
  } catch (e) {
    console.error(e)
  }
  return "Chromium";
};

export const chromium: IProductInfo = {
  ProductName: "A260",
  ProductTitle: `AHAU 260 for ${getBrowserName()}`,
  AppCode: Products.chromium,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://www.ahau260.com/",
  GetStartedPath: "https://ahausoftware.atlassian.net/wiki/spaces/AHAU260/pages/1161330694/Installation+for+Browsers",
  EndUserAgreementPath:
    "https://www.ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://www.ahau260.com/terms-of-use",
  OrderPath: "https://www.ahau260.com/pricing",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};

export const oa: IProductInfo = {
  ProductName: "A260",
  ProductTitle: "AHAU 260 for Microsoft Outlook",
  AppCode: Products.oa,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://www.ahau260.com/",
  GetStartedPath: "https://ahausoftware.atlassian.net/wiki/spaces/AHAU260/pages/1134297094/Installation+for+Microsoft+Outlook",
  EndUserAgreementPath:
    "https://www.ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://www.ahau260.com/terms-of-use",
  OrderPath: "https://www.ahau260.com/pricing",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};

export const teams: IProductInfo = {
  ProductName: "A260",
  ProductTitle: `AHAU 260 for Microsoft Teams`,
  AppCode: Products.teams,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://ahau260.com/",
  GetStartedPath: "https://ahausoftware.atlassian.net/wiki/spaces/AHAU260/pages/1161330964/Installation+for+Teams",
  EndUserAgreementPath:
    "https://www.ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://www.ahau260.com/terms-of-use",
  OrderPath: "https://www.ahau260.com/pricing",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};

export const web: IProductInfo = {
  ProductName: "A260",
  ProductTitle: `AHAU 260 for Web`,
  AppCode: Products.web,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://www.ahau260.com/",
  GetStartedPath: "https://ahausoftware.atlassian.net/wiki/spaces/AHAU260/pages/828047494/Getting+started",
  EndUserAgreementPath:
    "https://www.ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://www.ahau260.com/terms-of-use",
  OrderPath: "https://www.ahau260.com/pricing",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};


export const mobile: IProductInfo = {
  ProductName: "A260",
  ProductTitle: `AHAU 260 for Mobile`,
  AppCode: Products.mobile,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://www.ahau260.com/",
  GetStartedPath: "https://ahausoftware.atlassian.net/wiki/spaces/AHAU260/pages/1161330964/Installation+for+Teams",
  EndUserAgreementPath:
    "https://www.ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://www.ahau260.com/terms-d-use",
  OrderPath: "https://www.ahau260.com/pricing",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};

export const office: IProductInfo = {
  ProductName: "A260",
  ProductTitle: `AHAU 260 for Office Documents`,
  AppCode: Products.office,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://www.ahau260.com/",
  GetStartedPath: "",
  EndUserAgreementPath:
    "https://www.ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://www.ahau260.com/terms-d-use",
  OrderPath: "https://www.ahau260.com/pricing",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};

export const project: IProductInfo = {
  ProductName: "A260",
  ProductTitle: `AHAU 260 for Microsoft Project`,
  AppCode: Products.project,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://www.ahau260.com/",
  GetStartedPath: "",
  EndUserAgreementPath:
    "https://www.ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://www.ahau260.com/terms-d-use",
  OrderPath: "https://www.ahau260.com/pricing",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};

export const contextual: IProductInfo = {
  ProductName: "A260",
  ProductTitle: `AHAU 260`,
  AppCode: Products.ctx,
  ServiceProvider: ServiceProvider.A260,
  ServiceProviderPath: "https://www.ahau260.com/",
  GetStartedPath: "",
  EndUserAgreementPath:
    "https://www.ahau260.com/license-agreement",
  PrivacyPolicyPath:
    "https://www.ahau260.com/terms-d-use",
  OrderPath: "https://www.ahau260.com/pricing",
  LogoPath: "/images/A260/logo.png",
  DefaultConnection: ""
};
