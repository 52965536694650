import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import resources from './translations/translations';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

export const i18init = async (localLng: string | undefined) => {
  await i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      // resources,
      lng: localLng ? localLng : "en",
      fallbackLng: "en",
      // keySeparator: false,
      debug: true,
      detection: {
        order: ["queryString", "cookie"],
        caches: ["cookie"],
      },
      // ns: ["translations"],
      // defaultNS: "translations",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
};


export default i18n;
