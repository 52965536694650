import { ServiceProvider, FeatureCode } from 'consts';
export { ServiceProvider } from 'consts';

export enum Products {
  epoa = "epoa",
  epcc = "epcc",
  web = "web",
  epchromium = "epchromium",
  teams = "teams",
  chromium = "chromium",
  cc = "cc",
  oa = "oa",
  mobile = "mobile",
  office = "office",
  project = "project",
  ctx = "ctx"
}

/** per product config  */
export interface IProductInfo {
  ProductName?: string;
  ProductTitle?: string;

  AppCode?: Products;

  ServiceProvider: ServiceProvider;

  ServiceProviderPath: string;
  GetStartedPath: string;
  EndUserAgreementPath: string;
  PrivacyPolicyPath: string;
  OrderPath: string;
  LogoPath: string;
  DefaultConnection?: string;
}

/** client app env loaded using config.js */
export interface IAppEnv {
  Product: Products;
  ProductPath: string;
  AppVersion?: string;
  AppReleaseDate?: string;
  AppAmplifyId?: string;
  AppBranch?: string;
  AppCommitId?: string;
  IsDev?: boolean;
  /** value copied here from IHostEnv after host env is loaded  */
  IsStaging?: boolean;
  IsDebug?: boolean;
  AIInstrumentationKey?: string;
  MixpanelToken?: string;

  /** Server API base path with slash  */
  ServerRootAddress: string;
  /** SPA Client app base path with slash */
  ClientRootAddress: string;

  /** web root **/
  StartRootAddress: string;
  Auth0Domain: string;
  Auth0Api?: string;
  Auth0ClientId: string;
  Auth0DefaultConnection?: string;

  WebExtensionKeys?: string;
  Browser: string;
  ExcludeFeatures?: string;
  UseFetch: boolean;

  StripeKey: string;

  StripeMonthlyPriceId: string;
  StripeMonthlyTaxId: string;

  StripeYearlyPriceId: string;
  StripeYearlyTaxId: string;

  AcServerAddress: string;
}
