import { createHashHistory } from 'history';

// office addins require HashRouter
export const routerHistory = createHashHistory({
  basename: "",
  hashType: "slash"
});

// clone for easy coding
export default routerHistory;

