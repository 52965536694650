import { IProductInfo, Products } from "./env-types";

import { oa as epoa, cc as epcc, chromium as epchromium } from "./products/ep";
import { oa, cc, chromium, teams, web, mobile, office, project, contextual } from "./products/a260";


export const loadProductEnv = (product: Products) => {
  let productInfo: IProductInfo;
  switch (product) {
  case Products.epoa:
    productInfo = epoa;
    break;
  case Products.epcc:
    productInfo = epcc;
    break;
  case Products.epchromium:
    productInfo = epchromium;
    break;
  case Products.oa:
    productInfo = oa;
    break;
  case Products.cc:
    productInfo = cc;
    break;
  case Products.chromium:
    productInfo = chromium;
    break;
  case Products.teams:
    productInfo = teams;
    break;
  case Products.mobile:
    productInfo = mobile;
      break;
  case Products.office:
    productInfo = office;
      break;
  case Products.project:
    productInfo = project;
      break;
  case Products.ctx:
    productInfo = contextual;
      break;
      
  default:
    productInfo = web;
  }

  if (!productInfo) {
    console.warn("Product is not set");
  }

  return productInfo;
};
