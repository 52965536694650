/* eslint-disable import/first */
/// <reference types="@types/office-js" />
// This must be the first line in src/index.js
import "core-js/stable";
import "typeface-roboto/index.css";
import "devextreme/dist/css/dx.common.css";
import "office-ui-fabric-core/dist/css/fabric.min.css";
import "./assets/index.theme.css";
import "./index.css";
import "./styles";

import { I18nextProvider } from "react-i18next";
import i18n, { i18init } from "./i18n";

import "./helpers/globalAddons";

import { unregister } from "./serviceWorker";

import * as React from "react";
import * as ReactDOM from "react-dom";

import { ThemeProvider } from "@fluentui/react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Provider } from "react-redux";

import history from "./app/history";
import { ConnectedRouter } from "connected-react-router";
import { env, productInfo } from "env";
import { Products } from "env/env-types";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "AppInsights";
import timeout from "helpers/timeout";

console.time("app.import");
// amplify logging levels: VERBOSE, DEBUG, INFO, WARN, ERROR
window.LOG_LEVEL = "ERROR";

// import './assets/dx.generic.light-custom-scheme.css'
// import './assets/dx.generic.dark-custom-scheme.css'

initializeIcons();

console.time("app.init");

const isOffice365 = productInfo.AppCode === Products.epoa ||
  productInfo.AppCode === Products.oa || productInfo.AppCode === Products.office || productInfo.AppCode === Products.project;

const init = async () => {
  const rootElement = document.getElementById("root") as HTMLElement;
  // const envModule = await import("./env");
  // const storageServiceModule = await import("./services/StorageService");
  // const serverUnavailablePageModule = await import("./screens/ServerUnavailablePage");
  // try {
  //   const result = envModule.env;
  //   storageServiceModule.storage().setAppEnv(envModule.env);

  // } catch (error) {
  //   ReactDOM.render(<serverUnavailablePageModule.default error={error} />, rootElement);
  //   return;
  // }
  const store = await import("./services/StorageService");
  const localLng = await store.storage().getLocal<string>(store.LocalStorageKeys.Localize);

  
  try{
    //todo: fix error
    await i18init(localLng);
  }catch (e){
    console.error("An error while i18init {e}", e)
  }

  const storeModule = await import("./app/store");
  const debugModule = await import("./services/DebugService");
  const themesModule = await import("./themes");
  const servicesModule = await import("./services");

  const href = window.location.href;
  const hb_regex = /#\/https?:\/\/[^\s/$.?#].[^\s]*hubspot\.com/gi;
  //handle to broken address from hubspot iframe.  
  if(hb_regex.test(href)){
    console.debug("remove #/https://*.hubspot.com")
    window.location.replace(href.replace(hb_regex, ''));
    return;
  }

  // handle auth0 callbacks and redirect, + logout
  const pathparts = window.location.pathname?.split("/").filter((x) => x !== "");
  if (pathparts && pathparts.length > 0) {
    const lastpathpart = pathparts[pathparts.length - 1]?.toLowerCase();
    if (lastpathpart === "callback") {
      try {
        const query = new URLSearchParams(window.location.search);
        const authModule = await import("./features/auth/Auth");
        var result = await authModule.default.handleRedirectCallbackWithSignIn();
        console.warn("dbg result", result);
        var returnUrl = result.appState ? decodeURIComponent(result.appState) : "/";
        console.warn("dbg returnUrl", returnUrl);
        // force to redirect to home instead of login
        if (/login/.test(returnUrl) || /register/.test(returnUrl)) {
          returnUrl = "/";
        }
        // Using setTimeout to solve safari crash bug
        setTimeout(() => {
          if (query.has("pcode")) {
            window.location.replace(`${query.get("pcode")}/#${returnUrl}`);
          } else {
            window.location.replace(`/#${returnUrl}`);
          }
        }, 500);
        return;
      } catch (e) {
        console.warn("dbg callback error", e);
      }
    }
    if (lastpathpart === "logout") {
      try {
        const authModule = await import("./features/auth/Auth");
        await authModule.default.logout({
          client_id: env.Auth0ClientId,
          returnTo: env.ClientRootAddress,
        });
      } catch (e) {
        console.warn("dbg logout error", e);
      }
    }
  }

  debugModule.debug().init();
  await servicesModule.hostApp().init();
  themesModule.loadCurrentTheme();

  //save env for popup and other
  servicesModule.storage().setEnv(env);

  console.timeEnd("app.init");

  const appModule = await import("./App");
  ReactDOM.render(
    <React.StrictMode>
        <Provider store={storeModule.store}>
          <ConnectedRouter history={history}>
            <I18nextProvider i18n={i18n}>
              <AppInsightsContext.Provider value={reactPlugin}>
                <appModule.default/>
              </AppInsightsContext.Provider>
            </I18nextProvider>
          </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
    rootElement,
  );


};

//load Office.js dinamicly by product
if (isOffice365) {
  console.debug("Office.js script loading");

  const script = document.createElement("script");
  script.src = "https://appsforoffice.microsoft.com/lib/1/hosted/Office.js";
  script.id = "OfficeJs";
  script.type = "text/javascript";

  document.body.appendChild(script);

  script.onload = () => {
    if (window.Office !== undefined) {
      timeout(new Promise((resolve, reject) => {
        window.Office.onReady(() => {
          resolve(true);
        });
      }), 3000).finally(init);
    } else {
      init();
    }
  };
  script.onerror = () => {
    //anyway init app
    console.error("Error while loading Office.js script");
    init();
  };
} else {
  init();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
