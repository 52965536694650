// import { STATUS_CODES } from "http";
/**
 * Error Codes for common exception (to handle on client)
 */
export enum ErrorCodes {
  Unknown = 0,
  System = 100,
  ServiceClientException = 400,
  ServiceConnectionException = 503,
  ServiceNotFound = 404,
  ServiceDeserializeError = 415,
  /** external api call unauthorized (need reverify account connection?)  */
  ServiceUnauthorized = 401,
  ServiceActionForbidden = 403,
  ServiceVersionUnsupported = 501,
  TenantNotFound = 1000,
  /** api call unauthorized (need login) */
  UnauthorizedRequest,
  UnauthorizedSessionRequest,

  TenantCreationError = 1001,
}


export enum ErrorTypes {
  Undefined,
  Bug,
  ServiceConnection,
  DeletedAccount,
  ServiceError,
}
