
export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function timeout<T>(promise: Promise<T>, ms?: number) {
  return new Promise<T>((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Timeout'));
    }, ms || 3000);
    promise.then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      }
    );
  })
}