import {
  ActivityLookupItem,
  ContactLookupItem, DocumentLookupItem,
  IActivityLookupItem,
  IContactLookupItem,
  ILookupItem,
  IProjectLookupItem,
  ITypeaheadInput,
  LookupItem,
  ProjectLookupItem,
} from "../../gen";

export const MAX_RESULTS_COUNT = 50;
export const SKIP_COUNT = 50;

export enum LookupDataType {
  projects = "projects",
  tasks = "tasks",
  contacts = "contacts",
  documents = "documents",
}

export type LookupDataSourceItem<T = IProjectLookupItem | IActivityLookupItem | IContactLookupItem | ILookupItem> = T;

export interface LookupSelectedItems {
  project?: ProjectLookupItem | null;
  task?: ActivityLookupItem | null;
  contact?: ContactLookupItem | null;
  document?: DocumentLookupItem | null;
}

export interface LookupTaskData {
  id: string,
  loadFn: (requestData: Object) => Promise<LookupDataSourceItem[]>
}

export interface LookupLoaderTask {
  id: string,
  predefReqData: Object,
  hasMore: boolean,
  execute: (requestData: ITypeaheadInput) => Promise<LookupDataSourceItem[]>
}

export interface DataSourceLoader {
  id: string,
  setDefaultTasksState: () => void,
  hasMore: () => boolean,
  tasks: LookupLoaderTask[]
}

export interface LookupLoadResult {
  id: string,
  result: LookupDataSourceItem[],
  status: string,
  cacheData: boolean
}
