import { loadAppEnv as loadEnv } from "./load-env";
import { loadProductEnv } from "./product-env";

const loadedEnv = loadEnv();
export const env = loadedEnv.app;
export const appUrl = env.ClientRootAddress;
export const productInfo = loadedEnv.product;

console.log("env initialized.");

export default env;
