export enum ServiceProvider {
  Unknown = 'Unknown',
  Redmine = 'Redmine',
  HubSpot = 'HubSpot',
  Office365 = 'Office365',
  EasyRedmine = 'EasyRedmine',
  Plano = 'Plano',
  ChiliProject = 'ChiliProject',
  EasyProjects = 'EasyProjects',
  Asana = 'Asana',
  Jira = 'Jira',
  YouTrack = 'YouTrack',
  Fake = 'Fake',
  TestData = 'TestData',
  A260 = 'A260',
  Google = 'Google',
}

// reexported to use everywhere with importing gen first
// export { ServiceProvider } from 'src/gen/src/models';