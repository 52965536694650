import { IAppEnv, Products, IProductInfo } from "./env-types";
import { detect } from "detect-browser";
import { loadProductEnv } from './product-env';
const isDev = process.env.NODE_ENV === "development";

export const loadAppEnv = (): { app: IAppEnv, product: IProductInfo } => {

  let appEnv = {
    AppAmplifyId: process.env.REACT_APP_ENV_AMPLIFY_ID,
    AppVersion: process.env.REACT_APP_ENV_VERSION!,
    AppBranch: process.env.REACT_APP_ENV_BRANCH || "branch",
    AppCommitId: process.env.REACT_APP_ENV_COMMIT_ID || "abcd1234",
    AppReleaseDate: process.env.REACT_APP_ENV_RELEASEDATE!,


    ServerRootAddress: window.REACT_APP_SERVERROOTADDRESS || process.env.REACT_APP_SERVERROOTADDRESS || "https://server.root/",
    StartRootAddress: window.REACT_APP_STARTROOTADDRESS || process.env.REACT_APP_STARTROOTADDRESS || "https://start.ahau260.com",
    Auth0Domain: window.REACT_APP_AUTH0DOMAIN || process.env.REACT_APP_AUTH0DOMAIN!,
    Auth0ClientId: window.REACT_APP_AUTH0CLIENTID || process.env.REACT_APP_AUTH0CLIENTID!,
    Auth0Api: window.REACT_APP_AUTH0API || process.env.REACT_APP_AUTH0API,
    AIInstrumentationKey: (window.REACT_APP_AIKEY || process.env.REACT_APP_AIKEY) ?? "",
    MixpanelToken: (window.REACT_APP_MIXPANELTOKEN || process.env.REACT_APP_MIXPANELTOKEN) ?? "efeb3e9550ebd308d8192d7317ccce7c",
    ExcludeFeatures: (window.ENV_APP_EXCLUDE_FEATURES || process.env.REACT_APP_EXCLUDE_FEATURES) ?? "",

    Browser: "chrome",
    IsDev: isDev,

    IsStaging: (window.REACT_APP_ENV_IS_STAGING || process.env.REACT_APP_ENV_IS_STAGING) ?? false,
    StripeKey: window.REACT_APP_ENV_STRIPE_KEY || process.env.REACT_APP_ENV_STRIPE_KEY || "pk_test_loSGgqtBYsjyk47Y6O3jErDr",

    StripeMonthlyPriceId: window.REACT_APP_ENV_STRIPE_M_PRICE_ID || process.env.REACT_APP_ENV_STRIPE_M_PRICE_ID || "price_1HWkcHHmqL53V1UpsEiQlFAY",
    StripeMonthlyTaxId: window.REACT_APP_ENV_STRIPE_M_TAX_ID || process.env.REACT_APP_ENV_STRIPE_M_TAX_ID || "txr_1Ha4bPHmqL53V1UphSKg2IjZ",
    StripeYearlyPriceId: window.REACT_APP_ENV_STRIPE_Y_PRICE_ID || process.env.REACT_APP_ENV_STRIPE_Y_PRICE_ID || "price_1HWkcHHmqL53V1Upa5qpwgnf",
    StripeYearlyTaxId: window.REACT_APP_ENV_STRIPE_Y_TAX_ID || process.env.REACT_APP_ENV_STRIPE_Y_TAX_ID || "txr_1Ha4bPHmqL53V1UphSKg2IjZ",
    AcServerAddress: window.REACT_APP_ENV_LICENSE_SERVER || process.env.REACT_APP_ENV_LICENSE_SERVER || "https://lic.ahau260.com",

    UseFetch: true
  } as IAppEnv;

  const browserInfo = detect();

  if (browserInfo) {
    appEnv.Browser = browserInfo.name;
    if(appEnv.Browser === 'ios-webview' || appEnv.Browser === 'safari'){
      appEnv.UseFetch = false;

      console.debug("Use XHR");
    }
  }

  appEnv.ClientRootAddress = getClientAppUrl();
  appEnv.Product = loadProductCode();

  const pathparts = window.location.pathname?.split("/").filter((x) => x !== "");
  appEnv.ProductPath = pathparts.length > 0 ? pathparts[0]: appEnv.Product;

  const productInfo = loadProductEnv(appEnv.Product);

  appEnv.Auth0DefaultConnection = productInfo.DefaultConnection;

  return { app: appEnv, product: productInfo };
};

const loadProductCode = (): Products => {
  const pathname = window.location.pathname;
  const pathparts = pathname.split('/').filter(x => x !== "");

  //use query string to define product
  const query = new URLSearchParams(window.location.search);
  const prods = (Object as any).values(Products);

  if (pathparts.length > 0) {
    let productPath = pathparts[0].toLowerCase();
    let prod = prods.find((x: string) => productPath.startsWith(x));

    if(prod){
      return prod as Products;
    }

    const lastpathpart = pathparts[pathparts.length - 1]?.toLowerCase();
    if (lastpathpart === "callback") {
      if (query.has('pcode')) {
        productPath = query.get('pcode') ?? "";
        prod = prods.find((x: string) => productPath.startsWith(x));
        if(prod){
          return prod as Products;
        }
      }
      return Products.web;
    }
  }

  const pcode = (query.has('pcode') ? query.get('pcode') : undefined) || undefined;
  if (pcode && pcode.length > 0) {
    const prod = prods.find((x: string) => pcode.startsWith(x));
    if (prod) {
      if (!window.STORYBOOK_ENV) {
        const path = getClientAppUrlWithProductCode(pcode);
        window.location.assign(path);
        console.info("reload app to use product code from query");
      }

      return pcode as Products;
    }
  }
  if (!window.STORYBOOK_ENV) {
    console.info("reload app to use product code web");
    const path = getClientAppUrlWithProductCode(Products.web);
    window.location.assign(path);
  }

  return Products.web;
}

const getClientAppUrl = (): string => {
  return `${window.location.protocol}//${window.location.hostname}${(window.location.port ? ':' + window.location.port : '')}/`;
}

const getClientAppUrlWithProductCode = (productPath: string): string => {
  const search = window.location.search?.replace(`pcode=${productPath}&`, "").replace(`&pcode=${productPath}`, "").replace(`?pcode=${productPath}`, "");
  return `${window.location.protocol}//${window.location.hostname}${(window.location.port ? ':' + window.location.port : '')}/${productPath}${window.location.pathname}${search}${window.location.hash}`;
}
