import React from 'react';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { routerHistory } from 'app/history'
import { env } from './env'

export const reactPlugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
  config: {
    connectionString: env.AIInstrumentationKey,    
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableCorsCorrelation: true,
    enableAutoRouteTracking: true,
    correlationHeaderExcludedDomains: [
      '*.queue.core.windows.net',
      'login.ahau260.com'
    ],
    correlationHeaderDomains: [
      '*.app.ahau260.com',
      'app.ahau260.com',
      'a260.eu.ngrok.io',
      '*.ahausoftware.com',
      '*.ahau260.com',
      'localhost',
    ],
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: routerHistory }
    }
  }
});

if(env.AIInstrumentationKey){
  appInsights.loadAppInsights();

  var telemetryInitializer = (envelope : ITelemetryItem) => {
    if (envelope.tags){
      envelope.tags["ai.cloud.role"] = window.location.host;
      envelope.tags["ai.device.role"] = env.Product;
    }
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
}else{
  console.warn("AppInsights connection string is empty")
}