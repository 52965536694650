import { Products } from "env/env-types";
import { ServiceProvider } from "./ServiceProvider";
import { LookupDataType } from "components/lookup/constants";

/**
 * Configuration for navigation.
 */
export const Routes = {
  account_create: {
    // Todo - why cant get search params?
    url: (serviceProvider: ServiceProvider, returnUri?: string) =>
      `/account/create/${serviceProvider}${returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ``
      }`,
    path: "/account/create/:provider",
  },
  connection_edit: {
    url: (accountId: number, returnUri?: string) =>
      `/connection/${accountId!}${returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ``
      }`,
    path: `/connection/:id`,
  },
  account_authorize: {
    url: (accountId: number, returnUri?: string) =>
      `/connection/${accountId}/authorize${returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ``
      }`,
    path: "/connection/:id/authorize",
    pars: 1,
  },
  account_authorized: {
    url: (accountId: number, returnUri?: string) =>
      `/account/${accountId}/authorized${returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ``
      }`,
    path: "/account/:id/authorized",
    pars: 1,
  },
  account_address: {
    url: () => `/change/address`,
    path: "/change/address",
  },
  account_waitsync: {
    url: (accountId: number,  returnUri?: string) =>
      `/account/${accountId}/sync${returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ``
      }`,
    path: "/account/:id/sync",
  },
  sync_all_accounts: {
    url: () => `/accounts/sync/`,
    path: "/accounts/sync",
  },
  activity_create: {
    url: () => `/activity/new`,
    path: "/activity/new",
  },
  activity_convertnew: {
    url: () => `/activity/convertnew`,
    path: "/activity/convertnew",
  },
  activity_convertnew_callback: {
    url: (behaviour: "callback" | "pane") => `/activity/convertnew;return=${behaviour}`,
    path: "/activity/convertnew;return=:return",
  },
  activity_edit: {
    url: (id: string) => `/activity/edit/${id}`,
    path: "/activity/edit/:id",
  },
  activity_edit_next: {
    url: (id: string) => `/activity/edit-next/${id}`,
    path: "/activity/edit-next/:id",
  },
  email: {
    url: (id: string) => `/email/${id}`,
    path: "/email/:id",
  },
  bookmark: {
    url: (id: string) => `/bookmark/${id}`,
    path: "/bookmark/:id",
  },
  contact: {
    url: (id: string) => `/contact/${id}`,
    path: "/contact/:id",
  },
  channel: {
    url: (id: string) => `/channel/${id}`,
    path: "/channel/:id",
  },
  document: {
    url: (id: string) => `/document/${id}`,
    path: "/document/:id",
  },
  workspace_pane_id: {
    url: (id: string) => `/workspace/links/${id}`,
    path: "/workspace/links/:id",
  },
  channel_pane_id: {
    url: (id: string) => `/channel/links/${id}`,
    path: "/channel/links/:id",
  },
  linked_pane_id: {
    url: (id: string) => `/linked/links/${id}`,
    path: "/linked/links/:id",
  },
  linked_pane_links: {
    url: () => `/linked/links`,
    path: "/linked/links",
  },
  linked_pane_checklists: {
    url: () => `linked/checklists`,
    path: "/linked/checklists",
  },
  linked_pane_contacts: {
    url: () => `/linked/contacts`,
    path: "/linked/contacts",
  },
  linked_pane_timers: {
    url: () => `/linked/timers`,
    path: "/linked/timers",
  },
  home: {
    url: () => `/`,
    path: "/",
  },
  index: {
    url: () => `/index`,
    path: "/index",
  },
  settings: {
    url: () => "/settings/",
    path: "/settings",
  },
  login: {
    url: (returnUri?: string) =>
      "/login/" + (returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ""),
    path: "/login/",
  },
  register: {
    url: (returnUri?: string) =>
      "/register/" + (returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ""),
    path: "/register",
  },
  dashboard: {
    url: (dashboardPage?: string) =>
      "/dashboard/" + (dashboardPage ? `${dashboardPage}` : ""),
    path: "/dashboard",
  },
  gettingStarted: {
    url: (product: Products, returnUri?: string) =>
      `/${product}/getting-started` +
      (returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ""),
    path: (product: Products) => `/${product}/getting-started`,
  },
  error_page: {
    url: () => `/error`,
    path: "/error",
  },
  about: {
    url: () => "/about/",
    path: "/purchase",
  },
  purchase: {
    url: (serialNumber?: string, email?: string) =>
      `#/purchase?sn=${encodeURIComponent(
        serialNumber || ""
      )}&email=${encodeURIComponent(email || "")}`,
    path: "/purchase",
  },
  purchase_success: {
    url: (returnUri?: string) =>
      "#/purchase/success" +
      (returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ""),
    path: "/purchase/success",
  },
  purchase_canceled: {
    url: (returnUri?: string) =>
      "#/purchase/canceled" +
      (returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ""),
    path: "/purchase/canceled",
  },
  support_request_sent: {
    url: (isSuccessful?: boolean) => `/error` + (isSuccessful ? `?success=true` : ""),
    path: "/error",
  },
  goto_activity_edit: {
    url: (tab: LookupDataType = LookupDataType.projects) => `/goto/${tab.toString()}`,
    search: (search?: string, returnUri?: string) => search ? `?search=${encodeURIComponent(search!)}${(returnUri ? `&returnUri=${encodeURIComponent(returnUri!)}` : "")}`: "",
    path: "/goto/:type(Projects|Tasks|Contacts|Documents)",
  },

  favorites: {
    url: () => `/favorites`,
    path: "/favorites",
  },
  tasks: {
    url: () => `/tasks`,
    path: "/tasks",
  },
  actions: {
    url: () => `/actions`,
    path: "/actions",
  },
  workspaces: {
    url: () => `/workspaces`,
    path: "/workspaces",
  },
  send_to_id: {
    url: (id: string) => `/sento/${id}`,
    path: "/sento/:id",
  },
  send_to: {
    url: (returnUri?: string) => `/sento`  + (returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ""),
    path: "/sento",
  },
  send_to_callback: {
    url: () => `/sento;return=callback`,
    path: "/sento;return=:return",
  },
  export_to: {
    url: (returnUri?: string) => `/exportfileto`  + (returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ""),
    path: "/exportfileto",
  },
  link_to: {
    url: (returnUri?: string) => `/linkto`  + (returnUri ? `?returnUri=${encodeURIComponent(returnUri!)}` : ""),
    path: "/linkto",
  },
  linkto_workspace: {
    url: (tab: LookupDataType = LookupDataType.projects, workspaceId?: string, returnUri?: string) => `/linktoworkspace/${workspaceId}/${tab.toString()}${returnUri ? "?": ""}${(returnUri ? `returnUri=${encodeURIComponent(returnUri!)}` : "")}`,
    path: "/linktoworkspace/:workspaceId/:type(Projects|Tasks|Contacts|Documents)",
  },
  linkto_channel: {
    url: (tab: LookupDataType = LookupDataType.projects, channelId?: string, returnUri?: string) => `/linktochannel/${channelId}/${tab.toString()}${returnUri ? "?": ""}${(returnUri ? `returnUri=${encodeURIComponent(returnUri!)}` : "")}`,
    path: "/linktochannel/:channelId/:type(Projects|Tasks|Contacts|Documents)",
  }
};
