import { IProductInfo, ServiceProvider, Products } from "../env-types";
import { detect } from 'detect-browser';

export const cc: IProductInfo = {
  ProductName: "Easy Projects",
  ProductTitle: "Easy Projects for Adobe Creative Cloud",
  AppCode: Products.epcc,
  ServiceProvider: ServiceProvider.EasyProjects,

  ServiceProviderPath: "https://www.easyprojects.com/",
  GetStartedPath:
    "https://www.easyprojectsaddin.com/easyprojects-adobe-creative-cloud/get-started",
  EndUserAgreementPath:
    "https://www.easyprojectsaddin.com/easyprojects-adobe-creative-cloud/license-agreement",
  PrivacyPolicyPath:
    "https://www.easyprojectsaddin.com/easyprojects-adobe-creative-cloud/privacy-policy",
  OrderPath:
    "https://www.easyprojectsaddin.com/easyprojects-adobe-creative-cloud#prices",
  LogoPath: "/images/EasyProjects/addin-logo-48.png",
  DefaultConnection: ""
};

function getBrowserName(): string {
  try {
    const dt = detect();
    const browser = dt && dt.name;

    switch (browser) {
    case "firefox":
      return "Firefox";

    case "chrome":
      return "Chrome";

    case "edge-chromium":
      return "Edge";

    case "opera":
      return "Opera";
    }
  } catch (e) {
    console.error(e)
  }
  return "Chromium";
};

export const chromium: IProductInfo = {
  ProductName: "Easy Projects",
  ProductTitle: `Easy Projects for ${getBrowserName()}`,
  AppCode: Products.epchromium,
  ServiceProvider: ServiceProvider.EasyProjects,

  ServiceProviderPath: "https://www.easyprojects.com/",
  GetStartedPath:
    "https://www.easyprojectsaddin.com/easyprojects-chromium/get-started",
  EndUserAgreementPath:
    "https://www.easyprojectsaddin.com/easyprojects-chromium/license-agreement",
  PrivacyPolicyPath:
    "https://www.easyprojectsaddin.com/easyprojects-chromium/privacy-policy",
  OrderPath:
    "https://www.easyprojectsaddin.com/easyprojects-chromium#prices",
  LogoPath: "/images/EasyProjects/addin-logo-48.png",
  DefaultConnection: ""
};

export const oa: IProductInfo = {
  ProductName: "Easy Projects",
  ProductTitle: "Easy Projects for Outlook",
  AppCode: Products.epoa,
  ServiceProvider: ServiceProvider.EasyProjects,

  ServiceProviderPath: "https://www.easyprojects.com/",
  GetStartedPath:
    "https://www.easyprojectsaddin.com/easyprojects-outlook365/get-started",
  EndUserAgreementPath:
    "https://www.easyprojectsaddin.com/easyprojects-outlook365/license-agreement",
  PrivacyPolicyPath:
    "https://www.easyprojectsaddin.com/easyprojects-outlook365/privacy-policy",
  OrderPath:
    "https://portal.easyprojects.net/Payment/Cart?sku_EPO365-A=$quantity&accountAlias=$alias",
  LogoPath: "/images/EasyProjects/addin-logo-48.png",
  DefaultConnection: "ahausoftware-waad"
};
